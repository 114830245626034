import { useTheme } from '@mui/material/styles'

import { ThemeModeEnum } from '../../enums/ThemeModeEnum'

export const useStyles = () => {
	const theme = useTheme()
	const isDarkTheme = theme.palette.mode === ThemeModeEnum.DARK

	return {
		toastContainer: {
			'--toastify-color-success': theme.palette.success.main,
			'--toastify-color-info': theme.palette.info.main,
			'--toastify-color-warning': theme.palette.warning.main,
			'--toastify-color-error': theme.palette.error.main,

			'--toastify-text-color-success': theme.palette.background.paper,
			'--toastify-text-color-info': theme.palette.background.paper,
			'--toastify-text-color-warning': theme.palette.background.paper,
			'--toastify-text-color-error': theme.palette.background.paper,

			'.Toastify__toast-icon': {
				marginInlineEnd: '16px'
			},

			'.Toastify__close-button': {
				color: 'inherit'
			},

			'.toastify-soft-toast': {
				'--toastify-color-success': theme.palette.background.paper,
				'--toastify-color-info': theme.palette.background.paper,
				'--toastify-color-warning': theme.palette.background.paper,
				'--toastify-color-error': theme.palette.background.paper,

				'--toastify-text-color-success': isDarkTheme ? theme.palette.success.light : theme.palette.success.dark,
				'--toastify-text-color-info': isDarkTheme ? theme.palette.info.main : theme.palette.info.dark,
				'--toastify-text-color-warning': isDarkTheme ? theme.palette.warning.main : theme.palette.warning.dark,
				'--toastify-text-color-error': theme.palette.error.dark
			},

			'.toastify-outlined-toast': {
				'--toastify-color-success': theme.palette.background.paper,
				'--toastify-color-info': theme.palette.background.paper,
				'--toastify-color-warning': theme.palette.background.paper,
				'--toastify-color-error': theme.palette.background.paper,

				'--toastify-text-color-success': theme.palette.success.main,
				'--toastify-text-color-info': theme.palette.info.main,
				'--toastify-text-color-warning': theme.palette.warning.main,
				'--toastify-text-color-error': theme.palette.error.main,

				'&.Toastify__toast--success': {
					border: `1px solid ${theme.palette.success.main}`
				},
				'&.Toastify__toast--info': {
					border: `1px solid ${theme.palette.info.main}`
				},
				'&.Toastify__toast--warning': {
					border: `1px solid ${theme.palette.warning.main}`
				},
				'&.Toastify__toast--error': {
					border: `1px solid ${theme.palette.error.main}`
				}
			}
		}
	}
}
