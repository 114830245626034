import i18n from 'i18next'
import * as Yup from 'yup'

export const prepareCustomMessages = () =>
	Yup.setLocale({
		mixed: {
			required: () => i18n.t('general.validation.required')
		},
		string: {
			min: (params) => i18n.t('general.validation.min', { min: params.min }),
			max: (params) => i18n.t('general.validation.max', { max: params.max })
		}
	})
