import { useMemo } from 'react'
import i18n from 'i18next'
import * as Yup from 'yup'

import { OrderDtoPickupTimeEnum } from '../../generated/api/breadlove'
import { prepareCustomMessages } from '../../yup'

prepareCustomMessages()

export const useSchema = () => {
	return useMemo(
		() =>
			Yup.object().shape({
				pickupTime: Yup.mixed<OrderDtoPickupTimeEnum>()
					.required(i18n.t('general.validation.chooseOne'))
					.test((value) => Object.values(OrderDtoPickupTimeEnum).includes(value as OrderDtoPickupTimeEnum))
			}),
		[]
	)
}
