import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Button, Grid, Typography } from '@mui/material'

import { ScreenWrapper } from '../../components/ScreenWrapper'
import { Path } from '../../router/BaseRouter/enums'

export const NotFound = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const goHome = () => {
		navigate(Path.HOME)
	}

	return (
		<ScreenWrapper title={''}>
			<Grid container justifyContent='center'>
				<Grid item xs={12}>
					<Grid container justifyContent='center'>
						<Typography variant='h3' sx={{ ml: 2 }}>
							{t('notFound.404')}
						</Typography>
					</Grid>
				</Grid>
				<Grid item xs={12} sx={{ mt: 2 }}>
					<Grid container justifyContent='center'>
						<Typography variant='h3'>{t('notFound.title')}</Typography>
					</Grid>
				</Grid>
				<Grid item xs={12} sx={{ mt: 3 }}>
					<Grid container justifyContent='center'>
						<Typography variant='body1'>{`${t('notFound.text')} ${t('notFound.subText')}`}</Typography>
					</Grid>
				</Grid>
				<Grid item xs={12} sx={{ mt: 3 }}>
					<Grid container justifyContent='center'>
						<Button color='primary' variant='contained' onClick={goHome}>
							{t('notFound.button')}
						</Button>
					</Grid>
				</Grid>
			</Grid>
		</ScreenWrapper>
	)
}
