import { Control, Controller, FieldPath, FieldValues } from 'react-hook-form'
import { SxProps } from '@mui/material/styles'
import TextField, { TextFieldVariants } from '@mui/material/TextField'

type Props<Values extends FieldValues> = {
	name: FieldPath<Values>
	control: Control<Values>
	label?: string
	multiline?: boolean
	variant?: TextFieldVariants
	helperText?: string
	sx?: SxProps
	disabled?: boolean
}

export const TextFieldController = <Values extends FieldValues>({
	name,
	control,
	variant = 'filled',
	helperText,
	multiline,
	disabled,
	sx
}: Props<Values>) => {
	return (
		<Controller
			name={name}
			control={control}
			render={({ field: { onChange, name, ref, ...field }, fieldState }) => (
				<TextField
					inputRef={ref}
					{...field}
					name={name}
					variant={variant}
					hiddenLabel
					onChange={onChange}
					multiline={multiline}
					disabled={disabled}
					fullWidth
					size='medium'
					error={!!fieldState.error}
					helperText={fieldState.error?.message ?? helperText ?? undefined}
					sx={sx}
				/>
			)}
		/>
	)
}
