import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import sessionStorage from 'redux-persist/lib/storage/session'
import { combineReducers, configureStore } from '@reduxjs/toolkit'

import { persistOrderReducer } from './slices/persistOrderSlice'
import { sessionOrderReducer } from './slices/sessionOrderSlice'
import { userOptionsReducer } from './slices/userOptionsSlice'

const persistConfig = {
	key: 'root',
	version: 0,
	storage,
	whitelist: ['userOptions', 'persistOrder']
}

const sessionConfig = {
	key: 'session',
	version: 0,
	storage: sessionStorage,
	whitelist: ['sessionOrder']
}

export const persistedReducers = persistReducer(
	persistConfig,
	combineReducers({
		persistOrder: persistOrderReducer,
		userOptions: userOptionsReducer
	})
)

export const sessionReducers = persistReducer(
	sessionConfig,
	combineReducers({
		sessionOrder: sessionOrderReducer
	})
)

export const store = configureStore({
	reducer: combineReducers({ persistedReducers, sessionReducers }),
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false
		})
})

export const persistor = persistStore(store)

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
