import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { InferType } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Grid, Typography } from '@mui/material'
import Box from '@mui/material/Box'

import { DatePickerController } from '../../components/forms/fields/DatePickerController'
import { ScreenWrapper } from '../../components/ScreenWrapper'
import { Path } from '../../router/BaseRouter/enums'
import { sessionOrderActions, sessionOrderState } from '../../store/slices/sessionOrderSlice'
import { useSchema } from './schema'

export type Step1FormValues = InferType<ReturnType<typeof useSchema>>

export const Step1 = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const schema = useSchema()
	const dispatch = useDispatch()
	const order = useSelector(sessionOrderState)

	const { handleSubmit, control, reset } = useForm<Step1FormValues>({
		resolver: yupResolver(schema),
		defaultValues: {
			pickupDate: undefined
		}
	})

	useEffect(() => {
		reset({
			pickupDate: order.pickupDate ? new Date(order.pickupDate) : undefined
		})
	}, [])

	return (
		<ScreenWrapper
			step={1}
			routeBack={Path.HOME}
			title={'step1.title'}
			subTitle={'step1.subTitle'}
			onSubmit={handleSubmit((data) => {
				try {
					dispatch(sessionOrderActions.setStep1(data))
					navigate(Path.STEP2)
				} catch (e) {}
			})}
		>
			<Box style={{ width: '100%' }}>
				<Grid container direction='column' mb={2}>
					<Grid item>
						<Typography variant='subtitle2'>{t('step1.form.pickupDate')}</Typography>
					</Grid>
					<Grid item>
						<DatePickerController name='pickupDate' control={control} />
					</Grid>
				</Grid>
			</Box>
		</ScreenWrapper>
	)
}
