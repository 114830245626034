import { useMemo } from 'react'
import i18n from 'i18next'
import * as Yup from 'yup'

import { prepareCustomMessages } from '../../yup'

prepareCustomMessages()

export const useSchema = () => {
	return useMemo(
		() =>
			Yup.object().shape({
				products: Yup.array()
					.of(
						Yup.object().shape({
							productId: Yup.number().required(i18n.t('general.validation.shortMsg')),
							count: Yup.number()
								.required(i18n.t('general.validation.shortMsg'))
								.moreThan(-1, i18n.t('general.validation.shortMsg'))
						})
					)
					.required()
					.test({
						name: 'atLeastOneCountGreaterThanZero',
						message: i18n.t('general.validation.chooseOne'),
						test: (products) => products && products.some((product) => product.count > 0)
					})
					.required()
			}),
		[]
	)
}
