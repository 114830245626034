import React, { FC } from 'react'
import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'

import { ErrorBoundary } from '../../containers/ErrorBoundary'
import { BaseRouter } from '../../router/BaseRouter'
import { LSToastContainer } from '../LsToast'
import ScrollToTop from '../ScrollToTop'

import 'react-toastify/dist/ReactToastify.css'

export const Layout: FC = () => {
	return (
		<Box>
			<CssBaseline />
			<LSToastContainer />
			<Box>
				<ScrollToTop />
				<ErrorBoundary>
					<BaseRouter />
				</ErrorBoundary>
			</Box>
		</Box>
	)
}
