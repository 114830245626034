import { FC } from 'react'
import { Route, Routes } from 'react-router-dom'

import { Home } from '../../views/Home'
import { NotFound } from '../../views/NotFound'
import { Step1 } from '../../views/Step1'
import { Step2 } from '../../views/Step2'
import { Step3 } from '../../views/Step3'
import { Step4 } from '../../views/Step4'
import { Step5 } from '../../views/Step5'
import { Step6 } from '../../views/Step6'
import { Path } from './enums'

export const BaseRouter: FC = () => {
	return (
		<Routes>
			<Route path={Path.HOME} element={<Home />} />
			<Route path={Path.STEP1} element={<Step1 />} />
			<Route path={Path.STEP2} element={<Step2 />} />
			<Route path={Path.STEP3} element={<Step3 />} />
			<Route path={Path.STEP4} element={<Step4 />} />
			<Route path={Path.STEP5} element={<Step5 />} />
			<Route path={Path.STEP6} element={<Step6 />} />
			<Route path='*' element={<NotFound />} />
		</Routes>
	)
}
