import { toast as originalToast, ToastClassName, ToastContent, ToastOptions } from 'react-toastify'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ErrorIcon from '@mui/icons-material/Error'
import InfoIcon from '@mui/icons-material/Info'
import NewReleasesIcon from '@mui/icons-material/NewReleases'

type ToastVariant = 'outlined' | 'soft'

interface ExtendedToastOptions<T> extends ToastOptions<T> {
	variant?: ToastVariant
}

const getClassName = (vairant?: ToastVariant, className?: ToastClassName) => {
	const variantClassName = vairant !== undefined ? `toastify-${vairant}-toast` : undefined

	return variantClassName && className ? `${variantClassName} ${className}` : variantClassName || className
}

export const toast = {
	...originalToast,
	error: (content: ToastContent<unknown>, options?: ExtendedToastOptions<{}> | undefined) =>
		originalToast.error(content, {
			icon: ErrorIcon,
			...options,
			variant: undefined,
			className: getClassName(options?.variant, options?.className)
		} as ToastOptions<{}>),
	warning: (content: ToastContent<unknown>, options?: ExtendedToastOptions<{}> | undefined) =>
		originalToast.warning(content, {
			icon: NewReleasesIcon,
			...options,
			variant: undefined,
			className: getClassName(options?.variant, options?.className)
		} as ToastOptions<{}>),
	info: (content: ToastContent<unknown>, options?: ExtendedToastOptions<{}> | undefined) =>
		originalToast.info(content, {
			icon: InfoIcon,
			...options,
			variant: undefined,
			className: getClassName(options?.variant, options?.className)
		} as ToastOptions<{}>),
	success: (content: ToastContent<unknown>, options?: ExtendedToastOptions<{}> | undefined) =>
		originalToast.success(content, {
			icon: CheckCircleIcon,
			...options,
			variant: undefined,
			className: getClassName(options?.variant, options?.className)
		} as ToastOptions<{}>)
}
