import { useMemo } from 'react'
import i18n from 'i18next'
import * as Yup from 'yup'

import { prepareCustomMessages } from '../../yup'

prepareCustomMessages()

export const useSchema = () => {
	return useMemo(
		() =>
			Yup.object().shape({
				agreement: Yup.boolean()
					.required(i18n.t('general.validation.chooseOne'))
					.test('agreement', i18n.t('general.validation.agreement'), (value) => value === true)
			}),
		[]
	)
}
