import { useMemo } from 'react'
import * as Yup from 'yup'

import { prepareCustomMessages } from '../../yup'

prepareCustomMessages()

export const useSchema = () => {
	return useMemo(
		() =>
			Yup.object().shape({
				pickupDate: Yup.date().required()
			}),
		[]
	)
}
