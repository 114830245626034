import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { InferType } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Checkbox, FormControlLabel, Grid, Typography } from '@mui/material'
import FormHelperText from '@mui/material/FormHelperText'

import { toast } from '../../components/LsToast'
import { ScreenWrapper } from '../../components/ScreenWrapper'
import { TableSkeleton } from '../../components/TableSkeleton'
import { ProductDto } from '../../generated/api/breadlove'
import { Path } from '../../router/BaseRouter/enums'
import { createOrder } from '../../service/orders.service'
import { getProducts } from '../../service/products.service'
import { persistOrderState } from '../../store/slices/persistOrderSlice'
import { sessionOrderActions, sessionOrderState } from '../../store/slices/sessionOrderSlice'
import { useSchema } from './schema'

const FORMATTER = new Intl.DateTimeFormat('cs-CZ')

export type Step5FormValues = InferType<ReturnType<typeof useSchema>>

export const Step5 = () => {
	const [productsLoading, setProductsLoading] = useState<boolean>(true)
	const [buttonDisabled, setButtonDisabled] = useState<boolean>(false)
	const [products, setProducts] = useState<ProductDto[]>([])

	const { t } = useTranslation()
	const sessionOrder = useSelector(sessionOrderState)
	const persistOrder = useSelector(persistOrderState)
	const schema = useSchema()
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const { handleSubmit, control } = useForm<Step5FormValues>({
		resolver: yupResolver(schema),
		defaultValues: {
			agreement: false
		}
	})

	useEffect(() => {
		if (
			!sessionOrder.pickupDate ||
			!sessionOrder.pickupTime ||
			!sessionOrder.products ||
			sessionOrder.products.length === 0 ||
			!persistOrder.name ||
			!persistOrder.email ||
			!persistOrder.phoneNumber
		) {
			navigate(Path.HOME)
		}
		void loadProducts()
	}, [])

	const loadProducts = async () => {
		setProductsLoading(true)
		try {
			const res = await getProducts(new Date())
			setProducts(res.data)
		} catch {
			toast.error(t('general.notification.unexpectedError'))
		} finally {
			setProductsLoading(false)
		}
	}

	return (
		<ScreenWrapper
			step={5}
			routeBack={Path.STEP4}
			title={'step5.title'}
			subTitle={'step5.subTitle'}
			buttonTitle={'general.button.order'}
			buttonDisabled={buttonDisabled}
			onSubmit={handleSubmit(() => {
				setButtonDisabled(true)
				try {
					createOrder(sessionOrder, persistOrder)
						.then(() => {
							dispatch(sessionOrderActions.reset())
							navigate(Path.STEP6)
						})
						.catch(() => {
							toast.error(t('general.notification.unexpectedError'))
						})
						.finally(() => setButtonDisabled(false))
				} catch (e) {}
			})}
		>
			<Grid container direction='column' gap={2}>
				<Grid item>
					<Typography variant='h3' mb={1}>
						{t('step5.form.order')}
					</Typography>
					<Typography variant='body1'>
						{sessionOrder.pickupDate && sessionOrder.pickupTime
							? `${FORMATTER.format(new Date(sessionOrder.pickupDate))}, ${t(`step2.form.${sessionOrder.pickupTime}`)}`
							: ''}
					</Typography>
					{!productsLoading && (
						<>
							{sessionOrder.products
								?.filter((product) => product.count > 0)
								.map((product) => (
									<Typography key={product.productId} variant='body1'>
										{product.count}x {products.find((value) => value.id === product.productId)!.name}
									</Typography>
								))}
						</>
					)}
					{productsLoading && (
						<TableSkeleton
							spacing={1}
							height={21}
							size={
								sessionOrder.products ? sessionOrder.products.filter((product: any) => product.count > 0).length : 2
							}
						/>
					)}
				</Grid>
				<Grid item>
					<Typography variant='h3' mb={1}>
						{t('step5.form.aboutYou')}
					</Typography>
					<Typography variant='body1'>{persistOrder.name ? persistOrder.name : ''}</Typography>
					<Typography variant='body1'>{persistOrder.phoneNumber ? persistOrder.phoneNumber : ''}</Typography>
					<Typography variant='body1'>{persistOrder.email ? persistOrder.email : ''}</Typography>
				</Grid>
				<Grid item>
					<Controller
						control={control}
						name='agreement'
						render={({ field: { onChange, value }, fieldState }) => {
							return (
								<>
									<FormControlLabel
										control={<Checkbox checked={value} onChange={onChange} />}
										label={t('step5.form.agreement')}
									/>
									{fieldState.error?.message && (
										<FormHelperText sx={{ margin: 0 }} error>
											{fieldState.error.message}
										</FormHelperText>
									)}
								</>
							)
						}}
					/>
				</Grid>
			</Grid>
		</ScreenWrapper>
	)
}
