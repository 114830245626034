import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { OrderDtoPickupTimeEnum } from '../../generated/api/breadlove'
import { RootState } from '../index'

export type SessionOrderState = {
	pickupDate?: Date
	pickupTime?: OrderDtoPickupTimeEnum
	products?: { productId: number; count: number }[]
}

const initialState: SessionOrderState = {}

const sessionOrderSlice = createSlice({
	name: 'sessionOrder',
	initialState: initialState,
	reducers: {
		setStep1: (state, action: PayloadAction<{ pickupDate?: Date }>) => {
			state.pickupDate = action.payload.pickupDate
		},
		setStep2: (state, action: PayloadAction<{ pickupTime?: OrderDtoPickupTimeEnum }>) => {
			state.pickupTime = action.payload.pickupTime
		},
		setStep3: (state, action: PayloadAction<{ products?: { productId: number; count: number }[] }>) => {
			state.products = action.payload.products
		},
		reset: () => initialState
	}
})

export const sessionOrderReducer = sessionOrderSlice.reducer
export const sessionOrderActions = sessionOrderSlice.actions

const selectSlice = (state: RootState) => state.sessionReducers.sessionOrder

export const sessionOrderState = createSelector(selectSlice, (orderState: SessionOrderState) => orderState)
