import React from 'react'
import { Control, Controller, FieldPath, FieldValues } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import InputAdornment from '@mui/material/InputAdornment'
import { TextFieldVariants } from '@mui/material/TextField'
import { MobileDatePicker } from '@mui/x-date-pickers'

type Props<Values extends FieldValues> = {
	name: FieldPath<Values>
	control: Control<Values>
	variant?: TextFieldVariants
	disabled?: boolean
}

const isWeekend = (date: Date) => {
	const today = new Date()
	return (
		date.getDay() === 0 ||
		date.getDay() === 1 ||
		date.getDay() === 6 ||
		(date.getMonth() === today.getMonth() &&
			date.getFullYear() === today.getFullYear() &&
			date.getDate() === today.getDate())
	)
}

export const DatePickerController = <Values extends FieldValues>({
	name,
	control,
	variant = 'filled',
	disabled
}: Props<Values>) => {
	const { t } = useTranslation()

	return (
		<Controller
			name={name}
			control={control}
			disabled={disabled}
			render={({ field: { onChange, value, ref }, fieldState }) => (
				<MobileDatePicker
					disablePast
					format='EEEE - dd.MM.yyyy'
					shouldDisableDate={isWeekend}
					value={value}
					onChange={onChange}
					slotProps={{
						day: {
							sx: {
								'&.Mui-disabled:not(.Mui-selected)': {
									color: '#F0E8DC'
								}
							}
						},
						textField: {
							InputProps: {
								placeholder: t('general.button.choose'),
								endAdornment: (
									<InputAdornment position='end'>
										<CalendarMonthIcon />
									</InputAdornment>
								)
							},
							inputRef: ref,
							variant: variant,
							fullWidth: true,
							disabled: disabled,
							sx: {
								'& .MuiInputBase-input': {
									padding: '17px 16px',
									textTransform: 'capitalize',
									'&.MuiPickersDay-root.Mui-disabled:not(.Mui-selected)': {
										color: 'red'
									}
								}
							},
							error: !!fieldState.error?.message,
							helperText: fieldState.error?.message
						}
					}}
					sx={{
						width: '100%'
					}}
				/>
			)}
		/>
	)
}
