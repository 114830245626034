import React, { FC } from 'react'
import { Stack } from '@mui/material'
import Skeleton from '@mui/material/Skeleton'

type Props = {
	spacing: number
	size: number
	height: number
}
export const TableSkeleton: FC<Props> = ({ spacing, size, height }) => {
	return (
		<Stack spacing={spacing} width='100%'>
			{[...Array(size).keys()].map((value) => (
				<Skeleton key={value} variant='rounded' width={'100%'} height={height} />
			))}
		</Stack>
	)
}
