import { OrdersApi } from '../generated/api/breadlove'
import { PersistOrderState } from '../store/slices/persistOrderSlice'
import { SessionOrderState } from '../store/slices/sessionOrderSlice'
import axios from './axios'

const ordersApi = new OrdersApi(undefined, process.env.REACT_APP_BE_URL, axios)

const PICKUP_POINT_ID = 1
const TYPE = 'b2c'
export const createOrder = (sessionOrder: SessionOrderState, persistOrder: PersistOrderState) => {
	sessionOrder.pickupDate!.setHours(12)
	const products = sessionOrder.products!.filter((value) => value.count > 0)
	return ordersApi.createOrder({
		type: TYPE,
		email: persistOrder.email,
		name: persistOrder.name!,
		open: true,
		phoneNumber: persistOrder.phoneNumber,
		pickupDate: sessionOrder.pickupDate!.toISOString(),
		pickupTime: sessionOrder.pickupTime,
		pickupPointId: PICKUP_POINT_ID,
		emailNotification: true,
		products: products
	})
}
