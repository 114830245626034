import { FC, StrictMode } from 'react'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

import { persistor, store } from '../../store'
import { LocalizedThemedContainer } from '../LocalizedThemedContainer'

export const Root: FC = () => {
	return (
		<StrictMode>
			<Provider store={store}>
				<PersistGate persistor={persistor} loading={null}>
					<LocalizedThemedContainer />
				</PersistGate>
			</Provider>
		</StrictMode>
	)
}
