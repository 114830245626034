import { FC } from 'react'
import { ToastContainer, ToastContainerProps } from 'react-toastify'
import Box from '@mui/material/Box'

import { useStyles } from './styles'
import { toast } from './toast'

const LSToastContainer: FC<ToastContainerProps> = ({
	position = 'bottom-center',
	autoClose = 5000,
	hideProgressBar = false,
	newestOnTop = false,
	closeOnClick = true,
	rtl = false,
	pauseOnFocusLoss = true,
	draggable = true,
	pauseOnHover = true,
	theme = 'colored',
	...rest
}) => {
	const styles = useStyles()

	return (
		<Box sx={styles.toastContainer}>
			<ToastContainer
				position={position}
				autoClose={autoClose}
				hideProgressBar={hideProgressBar}
				newestOnTop={newestOnTop}
				closeOnClick={closeOnClick}
				rtl={rtl}
				pauseOnFocusLoss={pauseOnFocusLoss}
				draggable={draggable}
				pauseOnHover={pauseOnHover}
				theme={theme}
				{...rest}
			/>
		</Box>
	)
}

export { LSToastContainer, toast }
