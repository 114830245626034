import { FC } from 'react'
import { useSelector } from 'react-redux'
import locale from 'date-fns/locale/cs'
import { createTheme } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'

import { RootState } from '../../store'
import { App } from '../App'
import { I18nProvider } from '../I18nProvider'
import { themeOptions } from './themeOptions'

export const LocalizedThemedContainer: FC = () => {
	const language = useSelector((state: RootState) => state.persistedReducers.userOptions.locale)
	const theme = createTheme(themeOptions)

	return (
		<ThemeProvider theme={theme}>
			<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
				<I18nProvider language={language}>
					<App />
				</I18nProvider>
			</LocalizationProvider>
		</ThemeProvider>
	)
}
