import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import Box from '@mui/material/Box'
import StyledEngineProvider from '@mui/material/StyledEngineProvider'
import { useTheme } from '@mui/material/styles'

import { Layout } from '../../components/Layout'
import { ErrorBoundary } from '../ErrorBoundary'

export const App: React.FC = () => {
	const theme = useTheme()
	return (
		<Box sx={{ display: 'flex', justifyContent: 'center' }}>
			<Box
				sx={{
					backgroundColor: theme.palette.background.default,
					minHeight: '100dvh',
					maxWidth: '700px',
					width: '100%',
					'@media (max-width: 1024px)': {
						maxWidth: '100%'
					},
					color: theme.palette.primary.main
				}}
			>
				<StyledEngineProvider injectFirst>
					<BrowserRouter>
						<ErrorBoundary>
							<Layout />
						</ErrorBoundary>
					</BrowserRouter>
				</StyledEngineProvider>
			</Box>
		</Box>
	)
}
