import React from 'react'
import { useTranslation } from 'react-i18next'
import InstagramIcon from '@mui/icons-material/Instagram'
import { Button, Grid, Typography } from '@mui/material'
import Box from '@mui/material/Box'

import { Footer } from '../../components/Footer'
import { ScreenWrapper } from '../../components/ScreenWrapper'

export const Step6 = () => {
	const { t } = useTranslation()

	return (
		<ScreenWrapper step={6}>
			<Grid container direction='column' sx={{ height: 'calc(100vh - 40px)' }}>
				<Grid item xs={5}>
					<Box display='flex' justifyContent='center' alignItems='center' textAlign='center' sx={{ height: '100%' }}>
						<Box
							sx={{
								backgroundColor: '#F9DBEA',
								padding: (theme) => theme.spacing(8, 2, 3, 2),
								position: 'relative',
								borderRadius: '4px'
							}}
						>
							<Box sx={{ position: 'absolute', top: '-40px', left: '50%', transform: 'translate(-50%, 0)' }}>
								<svg width='81' height='81' viewBox='0 0 81 81' fill='none' xmlns='http://www.w3.org/2000/svg'>
									<circle cx='40.5' cy='40.5' r='36.5' fill='#F9DBEA' stroke='white' stroke-width='8' />
									<g transform='translate(23, 22)'>
										<path
											d='M26.0247 0.0565696C23.1625 -0.241031 20.5115 0.67045 18.5187 2.35022C17.9322 2.84456 17.0752 2.84456 16.4875 2.35022C14.4922 0.671695 11.8436 -0.241032 8.98275 0.0553242C3.81906 0.590757 0 5.18552 0 10.4216C0 20.0519 10.7089 30.9 15.3738 35.1698C16.5838 36.2767 18.4224 36.2767 19.6324 35.1698C24.296 30.9 35 20.0519 35 10.4228C35 5.18926 31.1859 0.594493 26.0247 0.0565696ZM9.91251 10.0966C7.50847 10.0966 5.55881 10.3107 5.55881 9.04686C5.55881 7.5078 7.50847 5.84672 9.91251 5.84672C12.3166 5.84672 14.2662 7.5078 14.2662 9.04686C14.2662 10.3107 12.3166 10.0966 9.91251 10.0966ZM25.0912 10.0966C22.6872 10.0966 20.7375 10.3107 20.7375 9.04686C20.7375 7.5078 22.6872 5.84672 25.0912 5.84672C27.4952 5.84672 29.4449 7.5078 29.4449 9.04686C29.4449 10.3107 27.4952 10.0966 25.0912 10.0966Z'
											fill='black'
										/>
									</g>
								</svg>
							</Box>
							<Typography variant='h2' mb={1}>
								{t('step6.title')}
							</Typography>
							<Typography variant='body1'>{t('step6.subTitle')}</Typography>
						</Box>
					</Box>
				</Grid>

				<Grid item xs={3}>
					<Box display='flex' justifyContent='center' alignItems='center' textAlign='center' sx={{ height: '100%' }}>
						<Box>
							<Typography variant='h3' mb={1}>
								{t('step6.form.title')}
							</Typography>
							<Typography variant='body1' mb={2}>
								{t('step6.form.subTitle')}
							</Typography>
							<Button
								startIcon={<InstagramIcon />}
								variant='contained'
								size='large'
								sx={{ width: '176px' }}
								onClick={() => window.open(process.env.REACT_APP_INSTAGRAM_URL, '_blank')}
							>
								{t('step6.form.follow')}
							</Button>
						</Box>
					</Box>
				</Grid>
				<Grid item xs={4}>
					<Box display='flex' justifyContent='center' alignItems='center' textAlign='center' sx={{ height: '100%' }}>
						<Footer />
					</Box>
				</Grid>
			</Grid>
		</ScreenWrapper>
	)
}
