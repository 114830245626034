import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { RootState } from '../index'

type UserOptionsState = {
	date: string
	locale: string
	darkTheme: boolean
}

const initialState: UserOptionsState = {
	date: new Date().toISOString(),
	locale: 'en',
	darkTheme: false
}

const userOptionsSlice = createSlice({
	name: 'userOptions',
	initialState: initialState,
	reducers: {
		updateDate: (state, action: PayloadAction<Date>) => {
			state.date = action.payload.toISOString()
		},
		updateLocale: (state, action: PayloadAction<string>) => {
			state.locale = action.payload
		},
		toggleTheme: (state) => {
			state.darkTheme = !state.darkTheme
		}
	}
})

export const userOptionsReducer = userOptionsSlice.reducer
export const userOptionsActions = userOptionsSlice.actions

const selectSlice = (state: RootState) => state.persistedReducers.userOptions

export const isDarkThemeSelector = createSelector(selectSlice, (userOptionsState) => userOptionsState.darkTheme)
export const dateSelector = createSelector(selectSlice, (userOptionsState) => userOptionsState.date)
